.tanishka_topHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 1rem;
    position: sticky !important;
    top: 0 !important;
    z-index: 9999;
    color: #222126;
    font-family: "Archivo Black", sans-serif;
}

.tanishkadiv_mobile {
    display: none;
}

.tanishkadiv_mobile button .fs-1textwhite {
    color: white;
    font-size: 38px;
}


.tanishka_topHeader .logo {
    width: 150px;
     
}

.tanishka_topHeader a {
    text-decoration: none;
    display: flex;
    align-items: center;

}

.tanishka_topHeader .ngo_compnyName {
    font-weight: bold;
    font-size: 24px;
    margin-left: 10px;
    color: #020202;
}

.rightbox_taniska {
    margin-right: 20px;
}

.tanishka_topHeader .tanishka_liauto {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
}

.tanishka_topHeader .tanishka_liauto li {
    margin: 0 .4rem;
    font-weight: 500;
    font-size: 18px;
}

.tanishka_topHeader .tanishka_liauto li a {
    padding: 0.5rem 0.4rem;
    border-radius: 6px;
    text-decoration: none;
    color: #020202;
}


.tanishka_topHeader .tanishka_liauto li a:hover {
    background: #494b49;
    border-radius: 6px;
}

.tanishka_topHeader .tanishka_liauto li a.active {
    background: #f93432;
    color: white;
    padding:0.4rem;
    border-radius: 6px;

}

.tanishka_topHeader .tanishka_liauto li .dropdown .btn {
    color: white;
    padding: 0.5rem 0.4rem;
    font-weight: 500;


}

.tanishka_topHeader .tanishka_liauto li .dropdown .btn:hover {
    background: #494b49;
    border-radius: 6px;
}

.tanishka_topHeader .tanishka_liauto li .dropdown .dropdown-menu.show{
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    width: 570px;
    padding: 20px;
}

.tanishka_topHeader .tanishka_liauto li .dropdown .dropdown-menu.show .dropdown-item
{
    width: 260px;
}
.custom-dropdown-menu {
    color: black !important;
}

.custom-dropdown-menu a {
    color: black !important;
}

.dropdown-menu {
    color: black !important;
}

.dropdown-menu .dropdown-item {
    color: black !important;
    font-weight: 600;
}

.dropdown-menu .dropdown-item:hover {
    color: white !important;
    font-weight: 600;

}
.nested-dropdown-menu {
    /* Add your custom styles for the nested dropdown menu here */
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 100%;
    /* Position it to the right of the parent dropdown */
}

.action:hover .nested-dropdown-menu {
    visibility: visible;
    position: absolute;
    margin-left: 99%;
    margin-top: -15%;
}

.action2:hover .nested-dropdown-menu {
    visibility: visible;
    position: absolute;
    margin-left: 99%;
    margin-top: 6%;

}


@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }

    to {
        transform: translateY(0rem);
    }
}

@media only screen and (max-width: 767px) {
    .tanishka_topHeader {
        z-index: 2;
        width: 100%;
        flex-direction: row-reverse;
    }

    .tanishkadiv_mobile {
        display: block;
    }

    .rightbox_taniska {
        display: none;
    }


    .tanishka_topHeader .logo {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        margin-left: 0px;
    }
    .tanishka_topHeader .ngo_compnyName {
        font-weight: bold;
        font-size: 15px;
        margin-left: 5px;
        color: white;
        text-align: center;
    }
}

