#BoxId {
    margin: 0 !important;
}

#BoxId .swiper .swiper-slide {
    padding-top: 0 !important;

}

#BoxId .swiper-pagination .swiper-pagination-bullet {
    width: 16px !important;
    height: 6px !important;
    border-radius: 2px !important;
}

.col-lg-6.col-12.textdivbox h1 {
    font-size: 60px;
}
.col-lg-6.col-12.textdivbox p {
    font-size: 22px;
}
.textdivbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 50px;
    font-style: italic;
}

#BoxId .customImgDiv img {
    height: 80vh !important;
    width: 100%;
    object-fit: contain;
    padding: 20px;
}


.Boost_about_div {
    text-align: center;
    padding: 20px 0;
    background-color: #ebecef;
    color: black;
}

.Boost_about_div h1 {
    font-family: "Archivo Black", sans-serif;
    color: black;
    font-weight: bold;
    padding-top: 20px;
}

.Boost_about_div h2 {
    color: black;
    font-weight: bold;
    margin-bottom: 60px;
    font-family: "Archivo Black", sans-serif;
}


.Boost_about_div_home {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

}


.Boost_about_div_home .imggdiv {
    padding: 10px 40px;

}

.Boost_about_div_home .imggdiv img {
    border-radius: 6px;
    height: 400px;
    object-fit: contain;
    border: 20px solid #fff;
    background-color: white;

}

.Boost_about_div_home .textdiv {
    padding: 10px 40px;
    text-align: start;

}

.Boost_about_div_home .textdiv h6 {
    font-size: 35px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: #f93432;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-family: "Archivo Black", sans-serif;
}

.Boost_about_div_home .textdiv h5 {
    font-size: 30px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: #f93432;
    margin-top: 20px;
}

.Boost_about_div_home .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: black;
    padding-top: 20px;
    text-align: justify;

}

.Boost_about_div_home .textdiv p img {
    width: 25px;
}


.Boost_about_div_home .textdiv .morebtn {
    background: linear-gradient(to right, #f93432, #18418b);
    color: white;
    border: 1px solid #f93432;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 30px;
    margin-top: 40px;
}

.Boost_about_div_home .textdiv .morebtn:hover {
    background: linear-gradient(to right, #f93432, #18418b);
    color: white;
    border: unset;

}


.section1 {
    background-color: #000000;
    min-height: 100vh;
    padding: 1rem 9% 6rem;
}

.section1 h1 {
    font-family: "Archivo Black", sans-serif;

}

.contact-us-left {
    margin-top: 1px;
    font-size: 18px;
    color: white;
    text-align: center;
}


.contact-us .contact-us-form {
    padding: 50px 40px;
}

.contact-us .contact-us-form h2 {
    position: relative;
    font-size: 32px;
    color: white;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin-bottom: 12px;
    padding-bottom: 20px;
    text-align: left;

}


.contact-us .contact-us-form h2:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    background: white;
}

.contact-us .contact-us-form p {
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-align: left;
    margin-bottom: 50px;
}

.contact-us .form {
    margin-top: 30px;
}

.contact-us .form .form-group {
    margin-bottom: 22px;
}

.contact-us .form .form-group input {
    width: 100%;
    height: 50px;
    border: 1px solid black;
    text-transform: capitalize;
    padding: 0px 18px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}

.contact-us .form .form-group textarea {
    width: 100%;
    height: 100px;
    border: 1px solid black;
    text-transform: capitalize;
    padding: 18px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}

.section1 .btnbtnbgprimary {
    background-color: #f93432;
    color: white;
}

.section1 .btnbtnbgprimary:hover {
    background-color: #1e4388;
    color: white;
}



.featured_projectweworkIn {
    margin: 0px 0;
    background-color: #ebecef;
    color: #1c1b1b;
    padding: 40px;
}

.logistic_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.logistic_topdiv h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
}

.tabsBox_Latest .tabs_latest .head_tabs {
    width: auto;
    display: flex;
    /* background-color: #ffffff; */
    border-radius: 8px;
    margin: 30px 0;

}

.mangetab1 {
    padding: 0.6rem 1.4rem;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #323234;
    text-align: start;
    width: auto;
    background-color: unset;
    border-bottom: 3px solid #efefef;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    /* box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1); */

}

.mangetab1:hover {
    color: #f93432;
    background-color: white;
    border-radius: 20px 20px 0 0;

}

.latest_TabPanelDiv {
    width: 100%;
    color: #1f1f20;

}



.Sustainability_div {
    background-color: #ebecef;
    padding: 60px 5px;
    text-align: center;
}

.Sustainability_div h1 {
    font-family: "Archivo Black", sans-serif;

}


.Sustainability_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Sustainability_slidediv a {
    text-decoration: none;
}

.Sustainability_div_slide {
    margin: 10px;
    width: 500px;
    height: 350px;
    text-align: center;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.Sustainability_div_slide img {
    height: 80px;
    /* border: 5px solid white; */
    width: 80px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.Sustainability_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 22px;
}

.Sustainability_div_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;

}

.Sustainability_div .btn {
    background-color: #f93432;
    color: white;
    margin-top: 30px;
    padding: 10px 40px;
    border-radius: 40px;
}

.Sustainability_div .btn:hover {
    background-color: #f93432;
    color: white;

}


.logistic_clients {
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 50px;
    background-color: #eee9e9;
}

.logistic_clients h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
    font-family: "Archivo Black", sans-serif;

}

.logistic_clients p {
    font-size: 16px;
}

.Projects_topimg {
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
    background-color: white;
}

.Projects_topimg img {
    height: 100px;
    width: 150px;
    object-fit: contain;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}

.Projects_topimg p {
    font-weight: bold;
    margin-top: 20px;
}

@media screen and (max-width:767px) {
    #BoxId .customImgDiv img {
        height: 250px !important;
    }

    #BoxId .swiper-button-prev {
        margin: 0 10px;
    }

    #BoxId .swiper-button-next {
        margin: 0 10px;
    }

    .Boost_about_div h2 {
        font-size: 19px;
        margin: 20px;
    }

    .Boost_about_div_home .imggdiv {
        padding: 10px 40px;

    }

    .Boost_about_div_home .imggdiv img {
        height: 300px;
        border: 2px solid #fff;

    }

    .Boost_about_div_home .textdiv {
        padding: 10px 20px;
    }

    .Boost_about_div_home .textdiv h6 {
        font-size: 30px;
    }

    .Boost_about_div_home .textdiv p {
        padding-top: 10px;

    }

    .logistic_topdiv {
        width: 100%;
    }

    .featured_projectweworkIn {
        padding: 20px;
    }
}