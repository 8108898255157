
.FeaturedProject_slide {
    padding: 10px 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FeaturedProject_slide a{
    text-decoration: none;
}


.project_fetaureddiv {
    margin: 20px 0px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    padding: 0;
    width: 350px !important;
    background-color: white;
    border-radius: 10%;

}

.project_fetaureddiv div img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10% 10% 0 0;
    margin:0;

}

.project_fetaureddiv div h6 {
    font-weight: bold;
    padding:20px;
    color: black;
    text-align: center;
    font-size: 20px;
    margin: 0 0 0;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 1;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .FeaturedProject_slide {
        padding: 10px 5px;
    }

    
}


@media screen and (max-width:767px) {
    .FeaturedProject_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .FeaturedProject_slide h1 {
        font-size: 18px;
    }

    .project_fetaureddiv div img {
        width: 100%;
        height: 300px;
    
    }

    
}

